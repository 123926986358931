@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/Muli.ttf') format('truetype')
}
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/Muli-Bold.ttf') format('truetype')
}
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: 600;
  src: url('./assets/Muli-BoldItalic.ttf') format('truetype')
}
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 100;
  src: url('./assets/Muli-ExtraLight.ttf') format('truetype')
}
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: 100;
  src: url('./assets/Muli-ExtraLightItalic.ttf') format('truetype')
}
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: 400;
  src: url('./assets/Muli-Italic.ttf') format('truetype')
}
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 200;
  src: url('./assets/Muli-Light.ttf') format('truetype')
}
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: 200;
  src: url('./assets/Muli-LightItalic.ttf') format('truetype')
}
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/Muli-SemiBold.ttf') format('truetype')
}
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: 500;
  src: url('./assets/Muli-Semi-BoldItalic.ttf') format('truetype')
}

* {font-family: 'Muli', Arial, Helvetica, sans-serif !important;}