body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Muli.df733025.ttf) format('truetype')
}
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 600;
  src: url(/static/media/Muli-Bold.1e36ee6d.ttf) format('truetype')
}
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: 600;
  src: url(/static/media/Muli-BoldItalic.baf5807f.ttf) format('truetype')
}
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/Muli-ExtraLight.7ba35f05.ttf) format('truetype')
}
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: 100;
  src: url(/static/media/Muli-ExtraLightItalic.bfef6875.ttf) format('truetype')
}
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: 400;
  src: url(/static/media/Muli-Italic.c31be844.ttf) format('truetype')
}
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 200;
  src: url(/static/media/Muli-Light.6b667c3d.ttf) format('truetype')
}
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: 200;
  src: url(/static/media/Muli-LightItalic.2dd62513.ttf) format('truetype')
}
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 500;
  src: url(/static/media/Muli-SemiBold.e74e966f.ttf) format('truetype')
}
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: 500;
  src: url(/static/media/Muli-Semi-BoldItalic.cf39d947.ttf) format('truetype')
}

* {font-family: 'Muli', Arial, Helvetica, sans-serif !important;}
